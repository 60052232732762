<template>
  <div class="container p-0 m-0">
    <div class="row full-heigth p-0 m-0">
      <loading :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"></loading>
      <div class="card m-0 pb-2">
        <div class="card-body">
          <button type="button" class="royalc-btn-close pt-2 pr-0 float-right" @click="onClose()"
            aria-label="Close modal"><i class="fas fa-times"></i></button>
          <h5 class="box-title mb-1 pb-1">Opções</h5>

          <hr>

          <!-- Atualização de situação -->
          <div class="row">
            <div class="col-12">
              <div class="row left">
                <status-selector v-model="newStatus" label="Nova situação" rootClassName="col-12 px-0" />
              </div>

              <div class="row">
                <div class="col-9 px-0 pt-2 left">
                  <small>Situação atual: </small><span :style="{color:status.color}">{{ status.label }}</span>
                </div>
                <div class="col-3 bottom-align-text">
                  <button :disabled="!newStatus" class="btn btn-sm btn-outline-light m-1" @click="changeStatus()">
                    Atualizar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <!-- Quadro central -->
          <div class="row mt-2">
            <!-- Botões esquerda -->
            <div class="col-lg-6 align-center pb-2">
              <!-- <div v-if="canEdit" class="row">
                <a class="btn btn-sm btn-block btn-outline-light m-1"
                  v-bind:href="$util.mountLink('BookingEdit', this.booking)">Editar</a>
              </div> -->

              <!-- <div class="row">
                <a class="btn btn-sm btn-block btn-outline-light m-1"
                  v-bind:href="$util.mountLink('BookingView', this.booking)">Detalhes</a>
              </div> -->

              <div class="row">
                <button v-if="canTakeOrThrow" class="btn btn-sm btn-block btn-outline-light btn-a-color m-1" @click="toogleBooking()">
                  <span v-if="isTheSeller">Devolver</span>
                  <span v-if="isAvailable">Utilizar</span>
                </button>
              </div>

              <div class="row">
                <button v-if="subsLoaded" class="btn btn-sm btn-block btn-outline-light btn-a-color m-1" @click="toogleSubscribe()">
                  <span v-if="isFollowing">Deixar de acompanhar</span>
                  <span v-if="!isFollowing">Acompanhar</span>
                </button>
              </div>

              <div v-if="canEdit" class="row">
                <a class="btn btn-sm btn-block btn-outline-light m-1"
                  v-bind:href="$util.mountLink('BookingFollowers', this.booking)">Editar Assinantes</a>
              </div>

              <!-- <div v-if="canEdit" class="row">
                <button class="btn btn-sm btn-block btn-outline-light m-1" @click="cancelBooking()">
                  Cancelar
                </button>
              </div> -->

              <!-- <div v-if="canEdit" class="row">
                <button class="btn btn-sm btn-block btn-outline-light m-1" @click="deleteBooking()">
                  Excluir
                </button>
              </div> -->
            </div>

            <!-- Mensagem -->
            <div class="col-lg-6 align-center bg-light">
                <h6 class="pt-2"><small>Adicionar comentário</small></h6>
                <custom-textarea
                  v-model="form.message"
                  name="message"
                  type="text"
                  :rows="4"
                  v-validate="{ required: false }"
                  :error="errors.first('form.message')"
                  background="#fff"
                  class="mb-1">
                </custom-textarea>
                <button class="btn btn-sm btn-block btn-outline-light bg-white mb-2"
                  :disabled="!form.message" v-on:click="sendMessage()">Enviar</button>
            </div>
          </div>

          <hr>

          <!-- Botões abaixo -->
          <div class="row">
            <div class="col-12 align-center">
                <a v-if="canEdit" class="btn btn-sm btn-outline-light m-1"
                  v-bind:href="$util.mountLink('BookingEdit', this.booking)">Editar</a>

                <a class="btn btn-sm btn-outline-light m-1"
                  v-bind:href="$util.mountLink('BookingView', this.booking)">Detalhes</a>

                <!-- <button v-if="canTakeOrThrow" class="btn btn-sm btn-outline-light m-1" @click="toogleBooking()">
                  <span v-if="isTheSeller">Devolver</span>
                  <span v-if="isAvailable">Utilizar</span>
                </button> -->

                <!-- <button :disabled="!subsLoaded" class="btn btn-sm btn-outline-light m-1" @click="toogleSubscribe()">
                  <span v-if="isFollowing">Deixar de seguir</span>
                  <span v-if="!isFollowing || !subsLoaded">Seguir</span>
                </button> -->

                <button class="btn btn-sm btn-outline-light btn-a-color m-1" @click="onClose()">
                  <span >Fechar</span>
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'

// Services
import BookingService from '@/services/BookingService'
import StatusSelector from './StatusSelector.vue'

export default {
  name: 'BookingOptionsView',
  components: {
    Loading,
    CustomTextarea,
    StatusSelector
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      messageLabel: 'Mensagem',
      form: {
        message: ''
      },
      subsLoaded: false,
      subscribers: [],
      newStatus: null
    }
  },
  beforeMount () {
    this.getSubscribers()
  },
  mounted () {
    this.mostra()
  },
  methods: {
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    isset (value) {
      return value !== null && value !== undefined && value !== ''
    },
    mostra () {
    },
    toogleSubscribe () {
      let _this = this
      _this.subsLoaded = false
      let formData = new FormData()
      formData.append('booking', this.booking.id)

      BookingService.toogleSubscribe(formData).then(res => {
        let subs = res.data.data.subscribers.map(x => (
          x.id_user.id
        ))
        _this.subscribers = subs
        _this.subsLoaded = true
      })
    },
    getSubscribers () {
      let _this = this

      return new Promise((resolve, reject) => {
        BookingService.getBookingSubscribers(this.booking.id).then(res => {
          let subs = res.data.data.map(x => (
            x.id_user.id
          ))
          _this.subscribers = subs
          _this.subsLoaded = true
        }).catch((err) => {
          reject(err)
        })
      })
    },
    toogleBooking () {
      if (this.isAvailable) {
        this.applyBooking()
        return
      }

      if (this.isTheSeller) {
        this.leaveBooking()
      }
    },
    applyBooking () {
      this.$emit('apply', this.booking.id, this.user)
    },
    leaveBooking () {
      this.$emit('leave', this.booking.id, this.user)
    },
    show () {
      this.$router.push({ name: 'BookingView', params: { id: this.booking.id } })
    },
    sendMessage () {
      let me = this
      let formData = new FormData()
      formData.append('booking', this.booking.id)
      formData.append('message', this.form.message)
      me.isLoading = true
      BookingService.sendMessage(formData).then(dataJson => {
        me.isLoading = false
        me.form.message = ''
      }).then(function (res) {
        let msg = `<h4>Sua mensagem foi enviada com sucesso!</h4>`
        global.instanceApp.$alertSwal.modalAlert(null, msg, 'success', '').then((result) => {})
        me.isLoading = false
        me.isPageLoading = false
      }).catch(() => {
      })
    },
    deleteBooking () {
      this.$emit('delete', this.booking.id)
    },
    updateBooking (status) {
      this.$emit('update', this.booking, status)
    },
    changeStatus () {
      this.updateBooking(parseInt(this.newStatus.id))
    },
    cancelBooking () {
      this.updateBooking(7)
    }
  },
  computed: {
    canEdit () {
      return this.isSuperAdmin || this.isBookingAdmin
    },
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    },
    isTheSeller () {
      let userInfo = this.user || null
      if (userInfo) {
        return userInfo.active_employee.id_table_origin === this.booking.idVendedor
      }
      return false
    },
    isTheRequester () {
      let userInfo = this.user || null
      if (userInfo) {
        return userInfo.active_employee.id_table_origin === this.booking.idSolicitante
      }
      return false
    },
    isAvailable () {
      return !this.booking.idVendedor
    },
    isFollowing () {
      return this.subscribers && this.subscribers.includes(this.user.id)
    },
    canTakeOrThrow () {
      return (this.isBookingRequester && this.isAvailable) || this.isTheSeller
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.left {
  text-align: left;
}

.bottom-align-text {
  display: inline-block;
  vertical-align: bottom  !important;
  float: none;
}

.btn-a-color {
  color: #534B41 !important;
}
</style>
