<template>
  <div class="container p-0 m-0">
    <div class="row full-heigth p-0 m-0">
      <loading :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"></loading>
      <div class="card card-fullheight m-0 pb-2">
        <div class="card-body">
          <button type="button" class="royalc-btn-close pt-2 pr-0 float-right" @click="onClose()"
            aria-label="Close modal"><i class="fas fa-times"></i></button>
          <h5 class="box-title mb-1 pb-1">Booking <small v-html="[formatStatus(booking.situacao), ' (', 'ID ', booking.id, ')'].join('')"></small></h5>
          <hr>

          <!-- Informações -->
          <div class="row mt-2">
            <div class="col-md-6 text-left pb-3">
              <small>Origem - Destino</small>
              <div class="font-weight-bold text-capitalize">
                {{ booking.origem }} - {{ booking.destino }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Mercadoria</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(booking.mercadoria) }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Vendedor</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(booking.vendedor) }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Solicitante</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(booking.solicitante) }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Processo</small>
              <div class="font-weight-bold">
                <a v-if="booking.processo" class="font-weight-bold"
                  v-bind:href="$util.mountLink('ProcessDetails', { id: this.booking.idProcesso })">{{ $util.formatEmpty(booking.processo) }}</a>
                <span v-else>-</span>
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Ref. Royal (Booking)</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(booking.numero) }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Armador</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(booking.armador) }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Navio</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(booking.navio) }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Cliente</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(booking.cliente) }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Data da solicitação</small>
              <div class="font-weight-bold">
                {{ booking.data_solicitacao ? $util.formatDate(booking.data_solicitacao) : '-' }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Previsão de Prontidão de Carga</small>
              <div class="font-weight-bold">
                {{ booking.dataPrevisaoProntidaoCarga ? $util.formatDate(booking.dataPrevisaoProntidaoCarga) : '-' }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Prontidão de Carga</small>
              <div class="font-weight-bold">
                {{ booking.dataProntidaoCarga ? $util.formatDate(booking.dataProntidaoCarga) : '-' }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>ETD</small>
              <div class="font-weight-bold">
                {{ booking.etd ? $util.formatDate(booking.etd) : '-' }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Deadline</small>
              <div class="font-weight-bold">
                {{ booking.deadLine ? $util.formatDate(booking.deadLine) : '-' }}
              </div>
            </div>

            <div class="col-md-6 text-left pb-3">
              <small>Equipamentos</small>
              <div v-for="equipment in equipments" :key="equipment" class="font-weight-bold">
                {{ equipment }}
              </div>
            </div>
          </div>

          <hr>

          <!-- Botões abaixo -->
          <div class="row">
            <div class="col-12 align-center">
                <a v-if="canEdit" class="btn btn-sm btn-outline-light m-1"
                  v-bind:href="$util.mountLink('BookingEdit', this.booking)">Editar</a>

                <a class="btn btn-sm btn-outline-light m-1"
                  v-bind:href="$util.mountLink('BookingView', this.booking)">Detalhes</a>

                <button v-if="canTakeOrThrow" class="btn btn-sm btn-outline-light btn-a-color m-1" @click="toogleBooking()">
                  <span v-if="isTheSeller">Devolver</span>
                  <span v-if="isAvailable">Utilizar</span>
                </button>

                <button :disabled="!subsLoaded" class="btn btn-sm btn-outline-light btn-a-color m-1" @click="toogleSubscribe()">
                  <span v-if="isFollowing">Deixar de seguir</span>
                  <span v-if="!isFollowing || !subsLoaded">Seguir</span>
                </button>

                <button class="btn btn-sm btn-outline-light btn-a-color m-1" @click="onClose()">
                  <span >Fechar</span>
                </button>

                <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onShow">
                  Console
                </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import BookingService from '@/services/BookingService'

export default {
  name: 'BookingDetailsView',
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      subsLoaded: false,
      subscribers: [],
      colors: [
        '#ff0000', /* solicitar:  vermelho */
        '#f1ba26', /* solicitado: amarelo */
        '#ff0000', /* rejeitado:  vermelho */
        '#5ec65f', /* disponível: verde */
        '#5ec65f', /* confirmado: verde */
        '#ababab', /* utilizando: cinza */
        '#ababab', /* utilizado:  cinza */
        '#ababab', /* cancelado:   cinza */
        '#ababab' /* cancelado:   cinza */
      ],
      operationOptions: [
        'Exportação',
        'Importação'
      ]
    }
  },
  beforeMount () {
    // this.mostra()
    this.getSubscribers()
  },
  mounted () {
  },
  methods: {
    onShow () {
    },
    onfocus () {
    },
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    isset (value) {
      return value !== null && value !== undefined && value !== ''
    },
    mostra () {
    },
    getSubscribers () {
      let _this = this

      return new Promise((resolve, reject) => {
        BookingService.getBookingSubscribers(this.booking.id).then(res => {
          let subs = res.data.data.map(x => (x.id_user.id))
          _this.subscribers = subs
          _this.subsLoaded = true
        }).catch((err) => {
          reject(err)
        })
      })
    },
    toogleSubscribe () {
      let _this = this
      _this.subsLoaded = false
      let formData = new FormData()
      formData.append('booking', this.booking.id)

      BookingService.toogleSubscribe(formData).then(res => {
        let subs = res.data.data.subscribers.map(x => (
          x.id_user.id
        ))
        _this.subscribers = subs
        _this.subsLoaded = true
      })
    },
    toogleBooking () {
      if (this.isAvailable) {
        this.applyBooking()
        return
      }

      if (this.isTheSeller) {
        this.leaveBooking()
      }
    },
    applyBooking () {
      this.$emit('apply', this.booking.id, this.user)
    },
    leaveBooking () {
      this.$emit('leave', this.booking.id, this.user)
    },
    edit () {
    },
    formatStatus (value) {
      if (value === null || value === undefined || !this.statusOptions.length) {
        return ' - '
      }

      var colors = [
        '#ff0000', /* solicitar:  vermelho */
        '#f1ba26', /* solicitado: amarelo */
        '#ff0000', /* rejeitado:  vermelho */
        '#5ec65f', /* disponível: verde */
        '#5ec65f', /* confirmado: verde */
        '#ababab', /* utilizando: cinza */
        '#ababab', /* utilizado:  cinza */
        '#ababab' /* cancelado:   cinza */
      ]

      let statusList = this.statusOptions.map((value, key) => {
        return {
          'id': key,
          'label': value,
          'color': colors[key]
        }
      })

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    fullStatus (value) {
      return this.statusOptions.length ? this.statusOptions[value] : 'não informado'
    },
    statusColor (value) {
      return parseInt(value) ? this.colors[value] : '#000000'
    },
    formatDateTime (date) {
      return this.$util.formatDateTime(date, 'pt-BR', ' - ')
    }
  },
  computed: {
    equipments () {
      return this.booking.equip.split(';')
    },
    canEdit () {
      return this.isSuperAdmin || this.isBookingAdmin
    },
    canTakeOrThrow () {
      return (this.isBookingRequester && this.isAvailable) || this.isTheSeller
    },
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    },
    isAvailable () {
      return !this.booking.idVendedor
    },
    isTheSeller () {
      let userInfo = this.user || null
      if (userInfo && userInfo.active_employee.id_table_origin === this.booking.idVendedor) {
        return true
      }
      return false
    },
    isFollowing () {
      return this.subscribers && this.subscribers.includes(this.user.id)
    },
    status () {
      return this.statusOptions.length ? this.statusOptions[this.booking.situacao] : '-'
    },
    operacao () {
      return this.operationOptions[this.booking.tipo_operacao - 1]
    },
    color () {
      return parseInt(this.booking.situacao) !== null ? this.colors[this.booking.situacao] : '#000000'
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.btn-a-color {
  color: #534B41 !important;
}
</style>
