<template>
  <div :class="rootClassName">
    <label for="booking.situacao" class="small grey-label">{{ label }}</label>
    <v-select class="grey-field" name="booking.situacao" label="label" :clearable="false" :searchable="false"
      v-model="selected" :options="statusList" v-validate="{ required_object: true }" @input="onSelect">
      <template slot="option" slot-scope="option">
        <div class="d-center">
          {{ option.label }}
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">
          {{ option.label }}
        </div>
      </template>
    </v-select>
    <transition name="slide" mode="">
      <small v-if="errors.first('booking.situacao')" class="royalc-error-field">
        {{ errors.first('booking.situacao') }}</small>
    </transition>
  </div>
</template>

<script>
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import BookingService from '@/services/BookingService'

export default {
  inheritAttrs: false,
  name: 'StatusSelectorField',
  components: {
    vSelect
  },
  beforeMount () {
    this.getStatusOptions()
  },
  created () {
    let _this = this
    _this.id = 'status_' + _this._uid
  },
  data () {
    return {
      statusOptions: [],
      statusList: [],
      id: null,
      timer: 0,
      selected: null,
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      }
    }
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    greyField: {
      type: Boolean,
      required: false,
      default: true
    },
    delay: {
      required: false,
      type: Number,
      default: 400
    },
    label: {
      type: String,
      required: false,
      default: 'Situação'
    },
    value: {
      required: true
    },
    rootClassName: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false
    }
  },
  methods: {
    getStatusOptions () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      BookingService.getStatusOptions(false).then(res => {
        _this.statusList = Object.entries(res.data.data).map((key) => {
          return {
            id: key[0],
            label: key[1]
          }
        })
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    onSelect (val) {
      this.$emit('input', val)
    }
  },
  watch: {
    value: function (newValue) {
      this.selected = newValue
    }
  }
}
</script>
